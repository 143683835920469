import { Elm } from '../Main.elm';

var storedProfile = localStorage.getItem('profile');

var storedSettings = localStorage.getItem('settings');
    
var storedAuthData = storedProfile ? { profile: JSON.parse(storedProfile)} : null;

var storedDocList = localStorage.getItem('doclist');
      
var mflags = { authData: storedAuthData, settings: JSON.parse(storedSettings) , docList: JSON.parse(storedDocList)}

var node = document.getElementById('elm-content');
	
var elmApp = Elm.Main.init({node:node, flags:mflags});

elmApp.ports.auth0ClearSaved.subscribe(function(opts) {
	console.log("Removing tokens...");
        localStorage.removeItem('profile');
        console.log("removed.");
});

// Store and retrieve the current settings
elmApp.ports.storeDocs.subscribe(function(data) {
    console.log("Saving Docs...");
    localStorage.setItem('doclist', JSON.stringify(data));
    console.log("saved");
});
      

// Store and retrieve the current settings
elmApp.ports.storeSettings.subscribe(function(data) {
    console.log("Saving settings...");
    localStorage.setItem('settings', JSON.stringify(data));
    console.log("saved");
});


// Store and retrieve the current settings
elmApp.ports.storeProfile.subscribe(function(data) {
    console.log("Saving profile...");
    localStorage.setItem('profile', JSON.stringify(data.profile));
    console.log("saved");
});
